$primary: #f37022; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

 @import 'https://fonts.googleapis.com/css?family=Gloria+Hallelujah|Josefin+Slab:400,700';

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;
	    // padding: 1.5em 2em;

	    @media (max-width: 767px) {
	    	height: 20px;
	    }
		
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

.navbar-header {
	@media (max-width: 991px) {
		width: 35%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
	
	.logo {	
		max-width: 250px;
		@media (max-width: 430px) {
			width: 40%;
			margin-top: 0.75em;
		}
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1em 3em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}


/** SITE CSS **/
h1,h2 {
	font-family: 'Gloria Hallelujah', sans-serif;
	line-height: 2em;
}

body {
	font-family: 'Josefin Slab', sans-serif;
	font-weight: bold;
	font-size: 2em;
}

img {
	transition: 1s;
}

.filter {
	filter: blur(2px) saturate(10%);
}

.banner {
    background: url(../img/banner.jpg) no-repeat top/cover;
    text-align: center;
    text-shadow: 0px 0px 5px $wht;
    padding: 2em 0em;

    h1 {
        line-height: 1.75em;
        font-style: italic;
    }
    
    ul {
        text-align: left;
        display: inline-block;
        font-size: 1.5em;
        text-transform: uppercase;
        font-style: italic;
        
        li {
            color: $primary;
            
            span {
                color: $blk;
                font-weight: 700;
            }
        }
    }
}


hr {
	width: 50%;
	border-color: $primary;
}


.noPad { padding: 0; }


	
.btn {
	@include btn1;
	margin: 2em 0em;
}


/** IMG SLIDE DOWN FOR CAPTION EFFECT **/
.demo-2 {
    position:relative;
    width:100%;
    height:17em;
    overflow:hidden;
    text-align: center;
    background-color: $primary;
	    @media (max-width: 1200px) {
			height: 14em;
    	}
		li { list-style: none; }
}

.demo-2 p,.demo-2 h2 {
    color:#fff;
    padding:10px;
    left:-20px;
    top:20px;
    position:relative
}


.demo-2 h2 {
    font-size:1.75em;
    line-height:24px;
    margin-top: 5px;
    font-family:'Josefin Slab', sans-serif;
    font-style: italic;
    font-weight: bold;

    @media (max-width: 1400px) {
    	line-height: 40px;
    }
}

.effect img {
    position:absolute;
    left:0;
    bottom: 0px;
    cursor: pointer;
    width: 105%;
    margin-left: -5px;
    -webkit-transition:bottom .3s ease-in-out;
    -moz-transition:bottom .3s ease-in-out;
    -o-transition:bottom .3s ease-in-out;
    transition:bottom .3s ease-in-out;

    @media (min-width: 1500px) {
    	bottom: -7rem;
    }

    @media (min-width: 1800px) {
    	bottom: -12rem;
    }
}

.effect img.top:hover {
    bottom: -27rem;
    padding-top:100px;

    @media (min-width: 1500px) and (max-width: 1800px) {
		bottom: -23rem;
    }

    @media (max-width: 1499px) {
    	bottom: -17rem;
    }

}
	
.banner2 { 
	background: url(../img/banner2.jpg) no-repeat top/cover; 
	padding: 10em 0em;
}

@media (max-width: 1200px) {
  .banner2 {
    padding: 2em 0em;
  }
}

/** END IMG SLIDE DOWN FOR CAPTION EFFECT **/